// extracted by mini-css-extract-plugin
export var anchor = "ZenlayerBareMetal__anchor__SqDVx";
export var backgroundImage = "ZenlayerBareMetal__backgroundImage__sRswA";
export var cloudProviderIconsBareMetalSection = "ZenlayerBareMetal__cloudProviderIconsBareMetalSection__G3Hcx";
export var column = "ZenlayerBareMetal__column__KHyJe";
export var completeControlWithBareMetalSection = "ZenlayerBareMetal__completeControlWithBareMetalSection__C4_8J";
export var jumbotronSection = "ZenlayerBareMetal__jumbotronSection__uPpgd";
export var map = "ZenlayerBareMetal__map__CVekp";
export var pricingAndConfigurationsSection = "ZenlayerBareMetal__pricingAndConfigurationsSection__zjJwW";
export var pricingAndConfigurationsSectionContainer = "ZenlayerBareMetal__pricingAndConfigurationsSectionContainer__ynYgZ";
export var requestEstimateBareMetalSection = "ZenlayerBareMetal__requestEstimateBareMetalSection__sGE5q";
export var row = "ZenlayerBareMetal__row__A5N4r";
export var signUpTodaySection = "ZenlayerBareMetal__signUpTodaySection__N9lVO";
export var subNavigationBareMetalSection = "ZenlayerBareMetal__subNavigationBareMetalSection__vvwr0";
export var whyBareMetalSection = "ZenlayerBareMetal__whyBareMetalSection__qis7Q";
export var wideContainer = "ZenlayerBareMetal__wideContainer__UR6ly";
export var wrapper = "ZenlayerBareMetal__wrapper__pp3FV";